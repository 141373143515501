import React from "react";
import Slider from "react-slick";
const settings ={
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 1000,
    responsive: [
        {
            breakpoint: 1200,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
           
            }
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
           
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
           
            }
          },
          {
            breakpoint: 576,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
           
            }
          },
          
    ]
    
  }

class AboutusTestimonials extends React.Component{
    render(){
        return(
            <section className="about-testimonials">
                <div className="container">
                <h1 class="text-left text-lg-center fs30">Loved by patients, trusted by doctors</h1>
                    <div className="row mt-4">
                        <div className="col-12">
                        <Slider {...settings} >
                        <div className="testimonials-card">
                               <p className="text-center">“MH Doctor is an excellent 
                                  medium to connect with patients. I really like their video quality and advanced features.”</p>
                                  <p className="text-center"><span className="fw-bolder">Dr. Arun Shah</span><br />
                                  Neurology (Breach Candy Hospital)</p>
                            </div>
                            <div className="testimonials-card">
                               <p className="text-center">“MH Doctor is an excellent 
                                  medium to connect with patients. I really like their video quality and advanced features.”</p>
                                  <p className="text-center"><span className="fw-bolder">Dr. Arun Shah</span><br />
                                  Neurology (Breach Candy Hospital)</p>
                            </div>
                            <div className="testimonials-card">
                               <p className="text-center">“MH Doctor is an excellent 
                                  medium to connect with patients. I really like their video quality and advanced features.”</p>
                                  <p className="text-center"><span className="fw-bolder">Dr. Arun Shah</span><br />
                                  Neurology (Breach Candy Hospital)</p>
                            </div>
                            <div className="testimonials-card">
                               <p className="text-center">“MH Doctor is an excellent 
                                  medium to connect with patients. I really like their video quality and advanced features.”</p>
                                  <p className="text-center"><span className="fw-bolder">Dr. Arun Shah</span><br />
                                  Neurology (Breach Candy Hospital)</p>
                            </div>
                           
                            </Slider>
                        </div>
                    </div>

                </div>
            </section>
        )
    }
}

export default AboutusTestimonials