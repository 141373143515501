import React, { Component } from 'react'
import { Container } from "react-bootstrap";
import Breadcrumb from "../../component/breadcrumb";
import capcha from "../../assets/images/capcha.png"
export default class CareerSub extends Component {
    render() {
        return (
            <React.Fragment>
                <Container id="career-sub">
                    <Breadcrumb url="/" chlidrenUrl="" text="Career Sub" />

                    <div className='row mt-4'>
                        <div className='col-lg-6 col-md-6 col-12'>
                            <div className='row'>
                                <div className='col-12 mb-2'>
                                    <h3 className='text-dark'>Senior customer service representative</h3>
                                </div>
                                <div className='col-6 my-2'>
                                    <h6 className='text-dark mb-1'>Qualification:</h6>
                                    <span>B Com</span>
                                </div>
                                <div className='col-6 my-2'>
                                    <h6 className='text-dark mb-1'>Experience:</h6>
                                    <span>1-3 Years</span>
                                </div>
                                <div className='col-6 my-2'>
                                    <h6 className='text-dark mb-1'>No. Of positions:</h6>
                                    <span>1</span>
                                </div>
                                <div className='col-6 my-2'>
                                    <h6 className='text-dark mb-1'>Location:</h6>
                                    <span>Gurgaon</span>
                                </div>
                                {/* <div className='col-6 my-2'>
                                    <h6 className='text-dark mb-1'>Job Description:</h6>
                                    <span>Senior customer service executive</span>
                                </div> */}
                                <div className='col-12 my-2'>
                                    <h6 className='text-dark mb-1'>Requirements & Skills:</h6>
                                    <span>Customer orientation</span>
                                </div>                         

                               
                            </div>
                        </div>

                        <div className='col-lg-6 col-md-6 col-12'>
                            <div className='container-fluid upload_career_cont px-0'>
                                <div className='row'>
                                    <div className='col-12'>
                                        <h6 className='text-dark upload_top mb-0'>Upload Details</h6>
                                    </div>
                                </div>
                                <hr className='my-0' />
                                <div className='container-fluid form_container'>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <div className="form-floating mt-2 mb-3">
                                                <input type="text" className="form-control" id="name" autoComplete="off" placeholder="Name" />
                                                <label htmlFor="name"> Name<sup className='text-primary'>*</sup> </label>
                                            </div>
                                        </div>
                                        <div className='col-12'>
                                            <div className="form-floating mb-3">
                                                <input type="text" className="form-control" id="email" autoComplete="off" placeholder="Email Address" />
                                                <label htmlFor="email"> Email Address<sup className='text-primary'>*</sup> </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-12 '>
                                            <div className="form-floating mb-3">
                                                <input type="text" className="form-control" id="pos_apply" autoComplete="off" placeholder="Position Applying For" />
                                                <label htmlFor="pos_apply"> Mobile Number<sup className='text-primary'>*</sup> </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-12'>
                                            <div className="input-group enter-otp">
                                                <input type="text" className="form-control " placeholder="Upload your CV" aria-label="Upload your CV" aria-describedby="basic-addon1" />
                                                <button type="submit" className="input-group-text rounded-end text-primary px-3 upload_button" id="basic-addon1">
                                                    Upload
                                                </button>
                                            </div>
                                            
                                        </div>

                                        <div className='col-xs-12 col-sm-8 col-lg-8 col-md-12 mt-2 mt-lg-0'>
                                            <div className='text-dark mb-1'>Please enter the characters you see in the box</div>
                                            <div className='captcha-text-career'>
                                                <img className="form-control captcha_width-career" width="84" height="28" alt="captcha" src={capcha} />
                                            </div>
                                            <span className='captcha-field'>
                                                <span className=' captch'>
                                                    <input type="text" placeholder="Enter Captcha" name="Name" size="40" className="form-control" id="captcha" />
                                                </span>
                                            </span>
                                        </div>
                                        <div className="col-xs-12 col-sm-4 col-lg-4  col-md-12 text-center d-flex align-self-center">
                                            <a href="#" className="btn btn-primary rounded-1 mt-2 mt-mb-0 mb-2 mb-sm-0 ">Let's Connect</a>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className='row mt-4'>
                        <div className='col-12'>
                            {/* <h6 className='text-dark mb-0 roles_font'>Roles and Responsilbilities</h6> */}
                            <h6 className='text-dark mb-0 roles_font'>Job Description</h6>
                        </div>
                    </div>

                    <div className='row mt-2 mb-2'>
                        <div className='col-12'>
                            <ul className='roles_list'>
                                <li>
                                Strong experience in Automated Testing of Web-based/Mobile/M-Site application 
                                </li>
                                <li>
                                Define and establish test automation strategy, process, and framework/architecture
                                </li>
                                <li>
                                Strong Java programming knowledge and OOPS concepts 
                                </li>
                                <li>
                                Ability to handle multiple parallel automation pipelines/projects. 
                                </li>
                                <li>
                                Hands-on experience in Java, JUnit/TestNG, Cucumber(BDD), Jenkins, Selenium, Appium, SoapUI/Postman/RestAssured, Git/GitHub
                                </li>
                                <li>
                                Exposure to DevOps processes will be an added advantage [Jenkins CI CD pipelines] 
                                </li>
                                <li>
                                Hands-on experience with Mobile Application Automation testing tools like Appium or any other tools which support Android and IOS platforms. 
                                </li>
                                <li>
                                Appium and Mobile automation knowledge will be a good value add. 
                                </li>
                                <li>
                                Implemented End to End Automation pipeline and automated Reports 
                                </li>
                                <li>
                                Has experience working on In sprint Automation model 
                                </li>
                                <li>
                                Proven experience in designing and implementing Automation Testing frameworks and methodology 
                                </li>
                                <li>
                                API automation testing using REST assured framework/architecture or POSTMAN collections.
                                </li>
                            </ul>
                        </div>
                    </div>


                </Container>
            </React.Fragment>
        )
    }
}
