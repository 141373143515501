import React from "react";
import { Link } from "react-router-dom";
import {Routes, Route, useNavigate} from 'react-router-dom';


const BreadcrumbWithUrl = ({
    breadcrumbs
}) => {
    const navigate = useNavigate();
    return (
        <React.Fragment>
            <nav>
                <ol className="breadcrumb">
                    {
                        breadcrumbs && breadcrumbs.map((item, index) => {
                            if (item.type === 1) {
                                return <li className="breadcrumb-item" key={index}><Link to={item.url}>{item.name}</Link></li>;
                            } else if(item.type === 4){
                                return <li className="breadcrumb-item" key={index} onClick={() => navigate(-1)} style={{cursor:'pointer'}}>{item.name}</li>;
                            }else if (item.type === 2) {
                                return <li className="breadcrumb-item text-capitalize" key={index} style={{cursor:'pointer'}}><span>{item.name}</span></li>;
                            } else if (item.type === 3) {
                                return <li className="breadcrumb-item active text-capitalize" key={index} style={{cursor:'pointer'}}><span>{item.name}</span></li>;
                            }
                        })
                    }
                </ol>
            </nav>
        </React.Fragment>
    )
}

export default BreadcrumbWithUrl;