import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import { persistReducer } from "redux-persist";

import location from "./slice/location";
import selectedCity from "./slice/selected-city";
import selectedPincode from "./slice/selected-pincode";
import delivery from "./slice/delivery-address";

import userProfile from "./slice/user-profile";
import allUsers from "./slice/all-users";
import callToken from "./slice/call-token";
import switchToken from "./slice/switch-token";
import selectedUser from "./slice/selected-user";
import vendor from "./slice/vendor";
import selectedMembers from "./slice/selected-members";
import selectedMemberId from "./slice/selected-member-id";
import packageDetail from "./slice/package-detail";
import packageDelivery from "./slice/package-delivery-address";
import patientDetail from "./slice/patient-detail";
import bookingSource from "./slice/booking-source";
import removeWallet from "./slice/remove-wallet";

const rootReducer = combineReducers({
  allUsers: allUsers,
  callToken: callToken,
  switchToken: switchToken,
  selectedUser: selectedUser,
  selectedMembers: selectedMembers,
  selectedMemberId: selectedMemberId,
  userProfile: userProfile,
  location: location,
  packageDetail: packageDetail,
  delivery: delivery,
  selectedCity: selectedCity,
  selectedPincode: selectedPincode,
  patientDetail: patientDetail,
  packageDelivery: packageDelivery,
  vendor: vendor,
  bookingSource: bookingSource,
  removeWallet: removeWallet,
});

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk],
});
