import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import videoCall from "../../assets/dust/video-call.svg";

import hospital from "../../assets/dust/print.svg";
import doctor from "../../assets/dust/doctor-3.svg";
import {
  applyCoupon,
  removeCoupon,
  removeWallet,
  addWallet,
} from "../../actions/post-call";
import { baseURL, tConvert } from "../../constants/app-constants";
import { setRemoveWalletReducer } from "../../redux/slice/remove-wallet";

const BookingSummaryPayment = ({
  consultT,
  memberSelect,
  bookAppointRes,
  setBookAppointRes,
  setBookSuccess,
  setBookSuccessTime,
}) => {
  const dispatch = useDispatch();
  const callToken = useSelector((state) => state.callToken.value);
  const switchToken = useSelector((state) => state.switchToken.value);

  const [modal, setModal] = useState(false);
  const [isTermRead, setIsTermRead] = useState(false);
  const [payUsingWallet, setPayUsingWallet] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [couponData, setCouponData] = useState(null);

  function handleClose() {
    setModal(false);
  }

  function getJWTtoken() {
    if (switchToken) {
      return switchToken;
    } else if (callToken) {
      return callToken;
    } else {
      return null;
    }
  }

  function applyCouponCode() {
    let JWTresponse = getJWTtoken();

    applyCoupon(
      {
        booking_id: bookAppointRes ? bookAppointRes.booking_id : null,
        coupon_code: couponCode,
        type: 1,
      },
      JWTresponse
    )
      .then(function (response) {
        let res = response.data;
        if (res && res.errors && res.errors.length > 0) {
          return toast.error(res.errors[0].message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
          });
        }

        setCouponData(res);
      })
      .catch(function (err) {});
  }

  function removeCouponCode() {
    let JWTresponse = getJWTtoken();

    removeCoupon(
      {
        booking_id: bookAppointRes ? bookAppointRes.booking_id : null,
      },
      JWTresponse
    )
      .then(function (response) {
        let res = response.data;
        if (res && res.errors && res.errors.length > 0) {
          return toast.error(res.errors[0].message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
          });
        }

        setCouponData(null);
      })
      .catch(function (err) {});
  }

  function manageWallet(value) {
    setPayUsingWallet(value);
    if (value) {
      return false;
    }

    let JWTresponse = getJWTtoken();

    // removeWallet(
    //   {
    //     booking_id: bookAppointRes ? bookAppointRes.booking_id : null,
    //   },
    //   JWTresponse
    // )
    //   .then(function (response) {
    //     let res = response.data;
    //     if (res && res.errors && res.errors.length > 0) {
    //       return toast.error(res.errors[0].message, {
    //         position: "top-right",
    //         autoClose: 3000,
    //         hideProgressBar: false,
    //         closeOnClick: true,
    //         pauseOnHover: false,
    //         draggable: false,
    //       });
    //     }
    //   })
    //   .catch(function (err) {});
  }

  function cancelBooking() {
    setBookAppointRes(null);
    setBookSuccess(false);
    setBookSuccessTime(false);

    setIsTermRead(false);
    setPayUsingWallet(false);

    setCouponCode("");
    setCouponData(null);
  }

  function paynow(total_amount, wallet_amount) {
    var url =
      bookAppointRes &&
      bookAppointRes.actions &&
      baseURL + bookAppointRes.actions[0].uri;
    let JWTresponse = getJWTtoken();

    if (payUsingWallet) {
      addWallet(
        {
          booking_id: bookAppointRes ? bookAppointRes.booking_id : null,
          wallet_amount:
            total_amount < wallet_amount
              ? Math.floor(total_amount)
              : Math.floor(wallet_amount),
        },
        JWTresponse
      )
        .then(function (response) {
          let res = response.data;
          dispatch(setRemoveWalletReducer({booking_id:bookAppointRes ? bookAppointRes.booking_id : null, wallet:res.wallet}))
          window.location.href = url;
          if (res && res.errors && res.errors.length > 0) {
            return toast.error(res.errors[0].message, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: false,
            });
          }
        })
        .catch(function (err) {});
    } else {
      window.location.href = url;
    }
  }
  return (
    <React.Fragment>
      {bookAppointRes && (
        <>
          <div className="mhc-patient-details">
            <div className="row">
              <div className="col-5">Patient </div>
              <div className="col-auto">
                <span>:</span>
              </div>
              <div className="col-6">
                <span className="fw-bold">
                  {memberSelect.first_name} {memberSelect.last_name}
                  <span>
                    <button
                      onClick={cancelBooking}
                      className="btn btn-sm btn-outline-primary ms-3 rounded-1"
                    >
                      Change
                    </button>
                  </span>
                </span>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-5">Mobile No. </div>
              <div className="col-auto">
                <span>:</span>
              </div>
              <div className="col-6">
                <span className="fw-bold">
                  +{memberSelect.isd_code} {memberSelect.mobile_number}
                </span>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-5">Consult Type </div>
              <div className="col-auto">
                <span>:</span>
              </div>
              <div className="col-6">
                <span className="fw-bold">
                  {/* consultT === "0" ? 'All' :  */}
                  {consultT === "1" ? "Virtual" : "At Hospital/ Clinic"}{" "}
                  {/* <img src={print} className="hospitallog" /> */}
                  <img src={consultT==='1' ? videoCall:hospital} className={consultT==='1'?'videoCallLog':'hospitallog'} />
                </span>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-5">Appointment Date &amp; Time </div>
              <div className="col-auto">
                <span>:</span>
              </div>
              <div className="col-6">
                <span className="fw-bold text-primary">
                  {bookAppointRes &&
                    bookAppointRes.booking_date +
                      " | " +
                      tConvert(bookAppointRes.booking_slot_time)}
                </span>
              </div>
            </div>
          </div>
          <div className="mhc-payment-details mt-4">
            <div className="row mt-2">
              {!couponData ? (
                <>
                  <div className="col-md-9 col-lg-9 col-xl-10">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control"
                        id="CouponCode"
                        placeholder="Coupon Code"
                        value={couponCode}
                        onChange={(e) => setCouponCode(e.target.value)}
                      />
                      <label htmlFor="name">Coupon Code </label>
                    </div>
                  </div>
                  <div className="col-md-3 col-lg-3 col-xl-2 mt-2 mt-md-0">
                    <button
                      className="btn btn-outline-secondary w-100 rounded-1 bg-white"
                      onClick={(e) => applyCouponCode()}
                    >
                      Apply
                    </button>
                  </div>
                </>
              ) : (
                <div className="col-12">
                  <label className="alert alert-success w-100">
                    Coupon code has been applied successfully.
                    <a
                      className="btn btn-link p-0 text-danger ms-1 text-decoration-none"
                      onClick={(e) => removeCouponCode()}
                    >
                      Remove coupon
                    </a>
                  </label>
                </div>
              )}
            </div>
            <div className="row mt-3">
              <div className="col-6">Consultation Fee </div>
              <div className="col-6 text-end">
                <span className="me-1">:</span>
                <span className="fw-bold">
                  INR {bookAppointRes && bookAppointRes.amount}
                </span>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-6">Registration Fee </div>
              <div className="col-6 text-end">
                <span className="me-1">:</span>
                <span className="fw-bold">
                  INR{" "}
                  {bookAppointRes &&
                    bookAppointRes.payment &&
                    bookAppointRes.payment.pay_on_arrival &&
                    bookAppointRes.payment.pay_on_arrival.registration_fees}
                </span>
              </div>
            </div>

            {couponData && (
              <div className="row mt-2">
                <div className="col-6">Coupon Discount </div>
                <div className="col-6 text-end">
                  <span className="me-1">:</span>
                  <span className="fw-bold">
                    INR {couponData.discount_amount}
                  </span>
                </div>
              </div>
            )}

            <div className="row mt-2">
              <div className="col-6">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="MyWallet"
                    checked={payUsingWallet}
                    onChange={(e) => manageWallet(e.target.checked)}
                  />
                  <label
                    className="form-check-label d-block d-sm-inline "
                    htmlFor="MyWallet"
                  >
                    Use MyWallet for Payment
                  </label>
                </div>
              </div>
              <div className="col-6 text-end">
                <span className="me-1">:</span>
                <span className="fw-bold">
                  INR {bookAppointRes && bookAppointRes.wallet_amount}
                </span>
              </div>
            </div>
            <div className="border-bottom border-light w-100 transition mt-3"></div>
            <div className="row mt-2">
              <div className="col-6">
                <span className="fw-bold">Total to be Paid</span>
              </div>
              <div className="col-6 text-end">
                <span className="fw-bold">
                  INR{" "}
                  {payUsingWallet
                    ? bookAppointRes &&
                      (bookAppointRes.total_amount >
                      bookAppointRes.wallet_amount
                        ? bookAppointRes.total_amount &&
                          (couponData
                            ? couponData.total_amount
                            : bookAppointRes.total_amount)
                        : 0)
                    : bookAppointRes &&
                      bookAppointRes.total_amount &&
                      (couponData
                        ? couponData.total_amount
                        : bookAppointRes.total_amount)}
                </span>
              </div>
            </div>
          </div>
          <div className="mhc-register-form mt-4">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                onClick={(e) => setIsTermRead(e.target.checked)}
                id="isTerm"
              />
              <label
                className="form-check-label  d-block d-sm-inline"
                htmlFor="flexCheckDefault"
              >
                I have read and agree to the{" "}
                <a
                  target="_blank"
                  href={
                    consultT &&
                    (consultT === "1"
                      ? "https://www.myhealthcare.co/mh-marketplace-patient-t-c/terms-and-conditions.html"
                      : "https://www.myhealthcare.co/terms-and-conditions/")
                  }
                  className="text-primary text-decoration-none"
                >
                  {" "}
                  Terms and Conditions.
                </a>
              </label>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-12 text-center text-md-end">
              {bookAppointRes === undefined ? (
                <>Loading...</>
              ) : (
                <>
                  {bookAppointRes.payment_type === 0 ? (
                    isTermRead ? (
                      <>
                        <button
                          onClick={cancelBooking}
                          className="btn btn-outline-secondary rounded-1"
                        >
                          Cancel
                        </button>
                        {consultT !== "1" ? (
                          <button
                            onClick={(e) => setModal(true)}
                            className="btn btn-outline-primary ms-3 rounded-1"
                          >
                            Pay Later
                          </button>
                        ) : (
                          ""
                        )}
                        <a
                          onClick={(e) =>
                            paynow(
                              bookAppointRes.total_amount,
                              bookAppointRes.wallet_amount
                            )
                          }
                          className="btn btn-primary ms-3 rounded-1"
                        >
                          Pay Now
                        </a>
                      </>
                    ) : (
                      <>
                        <button
                          onClick={cancelBooking}
                          className="btn btn-outline-secondary rounded-1"
                        >
                          Cancel
                        </button>
                        {consultT !== "1" ? (
                          <button
                            disabled
                            className="btn btn-outline-primary ms-3 rounded-1"
                          >
                            Pay Later
                          </button>
                        ) : (
                          ""
                        )}
                        <button
                          disabled
                          className="btn btn-primary ms-3 rounded-1"
                        >
                          Pay Now
                        </button>
                      </>
                    )
                  ) : bookAppointRes.payment_type === 1 ? (
                    isTermRead ? (
                      <>
                        <button
                          onClick={cancelBooking}
                          className="btn btn-outline-secondary rounded-1"
                        >
                          Cancel
                        </button>
                        <button
                          onClick={(e) => setModal(true)}
                          className="btn btn-outline-primary ms-3 rounded-1"
                        >
                          Pay Later
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          onClick={cancelBooking}
                          className="btn btn-outline-secondary rounded-1"
                        >
                          Cancel
                        </button>
                        <button
                          disabled
                          className="btn btn-primary ms-3 rounded-1"
                        >
                          Pay Later
                        </button>
                      </>
                    )
                  ) : isTermRead ? (
                    <>
                      <button
                        onClick={cancelBooking}
                        className="btn btn-outline-secondary rounded-1"
                      >
                        Cancel
                      </button>
                      <a
                        onClick={(e) =>
                          paynow(
                            bookAppointRes.total_amount,
                            bookAppointRes.wallet_amount
                          )
                        }
                        className="btn btn-primary ms-3 rounded-1"
                      >
                        Pay Now
                      </a>
                    </>
                  ) : (
                    <>
                      <button
                        onClick={cancelBooking}
                        className="btn btn-outline-secondary rounded-1"
                      >
                        Cancel
                      </button>
                      <button
                        disabled
                        className="btn btn-primary ms-3 rounded-1"
                      >
                        Pay Now
                      </button>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </>
      )}

      <Modal show={modal} onHide={handleClose} size="md">
        <Modal.Body>
          <p className="fs-6 text-center fw-bold">
            Using “Pay Now” option is more rewarding As you can earn/redeem
            wallet points.
          </p>
          <div className="row mt-4">
            <div className="col-12 text-center ">
              <a
                href={
                  bookAppointRes &&
                  bookAppointRes.actions &&
                  baseURL + bookAppointRes.actions[1].uri
                }
                className="btn btn-outline-primary rounded-1"
                onClick={handleClose}
              >
                Pay Later
              </a>
              <a
                className="btn btn-primary ms-3 rounded-1"
                onClick={(e) =>
                  paynow(
                    bookAppointRes.total_amount,
                    bookAppointRes.wallet_amount
                  )
                }
              >
                Pay Now
              </a>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <ToastContainer />
    </React.Fragment>
  );
};

export default BookingSummaryPayment;
