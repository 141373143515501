import React from "react";
import Slider from "react-slick";
const settings ={
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 1000,
    responsive: [
        {
            breakpoint: 1200,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
           
            }
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
           
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
           
            }
          },
          {
            breakpoint: 576,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
           
            }
          },
          
    ]
    
  }
class INTheNews extends React.Component{
    render(){
        return(
         <section className="about-news">
            <div className="container">
               <h1 class="text-left text-lg-center fs30">In the News</h1>
               <div className="row mt-4 ">
                <div className="col-12">
                <Slider {...settings} >
                <div className="news-card">
                    <h4>
                    MyHealthcare partners 
                        with Ashoka University 
                        for evidence-based 
                        clinical research
                   
                    </h4>
                    <p className="text-primary mb-4 fw-bolder"> ET HEALTHWORLD.COM</p>
                    <a href="#" class="btn btn-outline-primary news-btn rounded-1  px-3 py-1">Read More </a>
                 </div>
                 <div className="news-card">
                    <h4>
                    MyHealthcare launches 
                        AI enabled enterprise 
                        ecosystem                 
                   
                    </h4>
                    <p className="text-primary mb-4 fw-bolder"> ET HEALTHWORLD.COM</p>
                    <a href="#" class="btn btn-outline-primary news-btn rounded-1  px-3 py-1">Read More </a>
                 </div>
           
                 <div className="news-card">
                    <h4>
                    Build & Grow: Money 
                            Matters Season 3 | 
                            MyHealthcare: Shaping 
                            the digital healthcare 
                            revolution

                            ET HEALTHWORLD.COM
                   
                    </h4>
                    <p className="text-primary mb-4 fw-bolder"> ET HEALTHWORLD.COM</p>
                    <a href="#" class="btn btn-outline-primary news-btn rounded-1  px-3 py-1">Read More </a>
                 </div>
                 <div className="news-card">
                    <h4>
                    MyHealthcare 
                        strengthens leadership 
                        team with appointment 
                        of Sanjay Bapna as Chief 
                        Commercial Officer

                    
                   
                    </h4>
                    <p className="text-primary mb-4 fw-bolder">     FE HEALTHCARE</p>
                    <a href="#" class="btn btn-outline-primary news-btn rounded-1  px-3 py-1">Read More </a>
                 </div>
                 
                    </Slider>
         
                </div>
              
               </div>
            </div>
         </section>
        )
    }
}

export default INTheNews