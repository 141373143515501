import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Modal, Accordion } from "react-bootstrap";
import Breadcrumb from '../../component/breadcrumb/index'
import lalpath from "../../assets/dust/lalpathlabs.webp"

import { useDispatch } from "react-redux";
import { clearPackageDetailReducer, setPackageDetailReducer } from '../../redux/slice/package-detail';

import { carePackages } from "../../actions/get-calls";
import { decodeString } from "../../constants/app-constants";
import BreadcrumbWithUrl from "../../component/breadcrumb/breadcrumb-with-url";

const CarePackages = () => {

    const dispatch = useDispatch();
    const { categoryName, categoryId } = useParams();
    const [modal, setModal] = useState(false);
    const [catName, setCatName] = useState("");
    const [compareId1, setCompareId1] = useState(null);
    const [compareId2, setCompareId2] = useState(null);
    const [packages, setPackages] = useState(null);

    useEffect(async () => {
        setCatName(decodeString(categoryName));
        let catId = categoryId !== '_' ? categoryId : null;

        if (categoryName && categoryId) {
            let _packages = await carePackages();
            if (_packages && _packages.data && _packages.data.health_offers) {
                for (let index = 0; index < _packages.data.health_offers.length; index++) {
                    const el = _packages.data.health_offers[index];
                    if ((el.id == catId) && (el.category_name === decodeString(categoryName))) {
                        setPackages(el.packages);
                        break;
                    }
                }
            }
        }
    }, [])

    function handleClose() {
        setModal(false);
    }

    function setCompare(e) {
        if (e.target.checked) {
            if (!compareId1) {
                setCompareId1(parseInt(e.target.value));
            } else {
                setCompareId2(parseInt(e.target.value));
            }
        } else {
            if (compareId1 === parseInt(e.target.value)) {
                setCompareId1(null);
            } else {
                setCompareId2(null);
            }
        }
    }

    function setForwardData(packageDetail) {
        dispatch(clearPackageDetailReducer());
        dispatch(setPackageDetailReducer(packageDetail));
    }

    const breadCrumbData = [
        { type: 1, url: '/', name: 'Home' },
        { type: 1, url: '/care-package-categories', name: 'Care Packages' },
        { type: 3, url: '', name: catName.replaceAll('_', ' ') }
    ];

    return (
        <React.Fragment>
            <section className="homecare-category">
                <div className="container">
                    <BreadcrumbWithUrl breadcrumbs={breadCrumbData} />
                    <div className="row">
                        <div className="col-12 ">
                            <h1 className="fs-4 mb-1">{catName.replaceAll('_', ' ')}</h1>
                        </div>
                    </div>
                    <div className="row mt-2 mt-lg-4">
                        {
                            !packages ?
                                <div className="mhc-Loding my-5 text-center">
                                    <img src="../../loader.gif" alt="loader" />
                                </div>
                                : packages.map((item, index) => (
                                    <div className="col-lg-6 col-xl-4 mb-4" key={index}>
                                        <div className="mhc-package-tab ">
                                            <div className="d-flex justify-content-between align-items-start ">
                                                <div>
                                                    <p className="m-0 packg-tittle">{item.name} <i className="mhc-icon icon-mhc-info cursor-pointer"></i>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center mt-2">
                                                <div>
                                                    <span>
                                                        <del>INR {item.price}</del>
                                                    </span>
                                                    <span className="text-primary ms-3">INR {item.discounted_price}</span>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center mt-3">
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id={item.name.trim() + item.id}
                                                        value={item.id}
                                                        disabled={((compareId1 && compareId2) && ((item.id !== compareId1) && (item.id !== compareId2)))}
                                                        onChange={(e) => setCompare(e)} />
                                                    <label className="form-check-label">Compare Now</label>
                                                </div>
                                                <Link onClick={() => setForwardData(item)} to={`/care-package-booking`} className="btn btn-primary rounded-1">Book</Link>
                                            </div>
                                        </div>
                                    </div>
                                ))
                        }
                    </div>

                    {
                        (compareId1 && compareId2) && (
                            <div className="row my-4">
                                <div className="col-12 text-center">
                                    <Link to={`/care-package-comparison/${compareId1}/${compareId2}`} className="btn btn-primary rounded-1 px-4">Compare</Link>
                                </div>
                            </div>
                        )
                    }
                </div>
            </section>

            <Modal show={modal} onHide={handleClose} size="xl" className="mhc-package-modal">
                <Modal.Header closeButton>
                    <div className="d-flex justify-content-between align-items-center">
                        <div>
                            <h6 className="m-0">Aarogyam 1.2</h6>
                            <p className="m-0">Profile Includes (61 Parameters)</p>
                        </div>
                        <div>
                            <img src={lalpath} className="pacg-icon" />
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body>

                    <Accordion defaultActiveKey="" className="">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Liver Function Test (includes 11 tests)</Accordion.Header>
                            <Accordion.Body>
                                <ul className=" m-0">
                                    <li>Thyroid Stimulating Hormone</li>
                                    <li>Thyroxine - Total</li>
                                    <li>Triiodothyronine Total</li>
                                </ul>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>Liver Function Test (includes 11 tests)</Accordion.Header>
                            <Accordion.Body>
                                <ul className=" m-0">
                                    <li>Thyroid Stimulating Hormone</li>
                                    <li>Thyroxine - Total</li>
                                    <li>Triiodothyronine Total</li>
                                </ul>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>Liver Function Test (includes 11 tests)</Accordion.Header>
                            <Accordion.Body>
                                <ul className=" m-0">
                                    <li>Thyroid Stimulating Hormone</li>
                                    <li>Thyroxine - Total</li>
                                    <li>Triiodothyronine Total</li>
                                </ul>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header>Liver Function Test (includes 11 tests)</Accordion.Header>
                            <Accordion.Body>
                                <ul className=" m-0">
                                    <li>Thyroid Stimulating Hormone</li>
                                    <li>Thyroxine - Total</li>
                                    <li>Triiodothyronine Total</li>
                                </ul>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </Modal.Body>

            </Modal>
        </React.Fragment>
    )
}

export default CarePackages;