import React, { useState, useEffect } from 'react';
import { Card, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

import Breadcrumb from '../../component/breadcrumb'
import { packageByHospital } from '../../actions/get-calls';
import { clearPackageDetailReducer, setPackageDetailReducer } from '../../redux/slice/package-detail';
import { useDispatch } from 'react-redux';

const HomecareCategoryPackages = () => {

    const dispatch = useDispatch();
    const { categoryId, hospitalId } = useParams();
    const [filterModal, setFilterModal] = useState(false);
    const [hospitalPackages, setHospitalPackages] = useState(null);
    const [packageFeatures, setPackageFeatures] = useState(null);

    const [compareId1, setCompareId1] = useState(null);
    const [compareId2, setCompareId2] = useState(null);

    useEffect(() => {
        setCompareId1(null);
        setCompareId2(null);
        packageByHospital(categoryId, hospitalId, 1, 'all')
            .then(function (response) {
                let res = response.data;
                if (res && res.test) {
                    setHospitalPackages(res.test);
                }
            })
            .catch(function (err) { });
    }, []);

    function setCompare(e) {
        if (e.target.checked) {
            if (!compareId1) {
                setCompareId1(parseInt(e.target.value));
            } else {
                setCompareId2(parseInt(e.target.value));
            }
        } else {
            if (compareId1 === parseInt(e.target.value)) {
                setCompareId1(null);
            } else {
                setCompareId2(null);
            }
        }
    }

    function handleShow(feature) {
        setFilterModal(true);
        setPackageFeatures(feature);
    }

    function handleClose() {
        setFilterModal(false);
        setPackageFeatures(null);
    }

    function setForwardData(packageDetail) {
        dispatch(clearPackageDetailReducer());
        dispatch(setPackageDetailReducer(packageDetail));
    }

    return (
        <React.Fragment>
            <section className="homecare-category">
                <div className="container">
                    <Breadcrumb url="" chlidrenUrl="" text="Homecare Service Packages" />
                    <div className="row">
                        <div className="col-lg-6 ">
                            <h1 className="fs-4 mb-1">Homecare Service Packages</h1>
                        </div>
                    </div>
                    <div className="row mt-3">

                        {!hospitalPackages ?
                            <div className="mhc-Loding my-5 text-center">
                                <img src="../../loader.gif" alt="loader" />
                            </div>
                            :
                            hospitalPackages.map((item) => (
                                <div className=" col-lg-4 col-md-6 col-sm-12 col-12 mb-4" key={item.id}>
                                    <Card className="homecare-category-card">
                                        <Card.Body>
                                            <div className="row">
                                                <div className="col-12 d-flex justify-content-between">
                                                    <div className="w-75">
                                                        <h6>{item.title}</h6>
                                                        <p className="text-secondary">Starting at INR {item.perday_price}</p>
                                                    </div>
                                                    <div className="w-25">
                                                        <Card.Img variant="top" className="image" src={item.image} alt={item.title} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row align-items-center justify-content-between">
                                                <div className="col-auto">
                                                    <div className="form-check">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id={item.title.trim() + item.id}
                                                            value={item.id}
                                                            disabled={((compareId1 && compareId2) && ((item.id !== compareId1) && (item.id !== compareId2)))}
                                                            onChange={(e) => setCompare(e)} />
                                                        <label className="form-check-label">Compare Now</label>
                                                    </div>
                                                </div>
                                                <div className="col-auto text-end">
                                                    <div className="d-flex justify-content-end">
                                                        <button className="btn btn-outline-secondary rounded-1 me-2" onClick={() => handleShow(item)}>Details</button>
                                                        <Link className="btn btn-primary rounded-1" onClick={() => setForwardData(item)} to={`/homecare-service-booking`}>Book</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </div>
                            ))
                        }
                    </div>
                    {
                        (compareId1 && compareId2) && (
                            <div className="row my-4">
                                <div className="col-12 text-center">
                                    <Link to={`/homecare-service-package-compare/${hospitalId}/${compareId1}/${compareId2}`} className="btn btn-primary rounded-1 px-4">Compare</Link>
                                </div>
                            </div>
                        )
                    }
                </div>
            </section>

            <Modal show={filterModal} onHide={() => handleClose()}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    {
                        packageFeatures && (
                            <div className='container homecare-details-modal'>
                                <div className='row'>
                                    <div className='col-12'>
                                        <div className='d-flex justify-content-between align-items-top'>
                                            <h5 className='text-danger'>{packageFeatures.title}</h5>
                                            <span className='img-holder'>
                                                <img src={packageFeatures.image} alt={packageFeatures.title} className='vendor-image'></img>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className='row mt-2'>
                                    <div className='col-12'>
                                        <span className="text-dark">Starting at INR {packageFeatures.perday_price}</span>
                                    </div>
                                </div>
                                <div className='row mt-3'>
                                    <div className='col-12'>
                                        <h6 >Package Inclusions</h6>
                                        <ul>
                                            {
                                                packageFeatures.features && packageFeatures.features.map((item, index) => {
                                                    return <li key={index}>{item}</li>
                                                })
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </Modal.Body>
            </Modal>
        </React.Fragment>
    )
}

export default HomecareCategoryPackages;