import React from "react";
import Slider from "react-slick";
import Investorshunch from "../../assets/images/Investors-hunch.webp"
import Investorssixihsence from "../../assets/images/Investors-sixih-sence.webp"
import Investorsisd from "../../assets/images/Investors-isd.webp"
import InvestorsAjay from "../../assets/images/Investors-ajay.webp"

const investorsettings ={
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 1000,
    responsive: [
        {
            breakpoint: 1200,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
           
            }
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
           
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
           
            }
          },
          {
            breakpoint: 576,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
           
            }
          },
          
    ]
    
  }
export default class OurInvestors extends React.Component{
    render(){
        return(
            <section className="about-investor">
            <div className="container">
               <h1 class="text-left text-lg-center fs30">Our Investors</h1>
               <div className="row mt-4 justify-content-center">
                <div className="col-xxl-12 col-xl-12 ">
                 <Slider {...investorsettings} >
                   <div className="investor-img">
                   <img src={Investorshunch} alt="" />
                   </div>
                   <div className="investor-img">
                   <img src={Investorssixihsence} alt="" />
                   </div>
                   <div className="investor-img">
                   <img src={Investorsisd} alt="" />
                   </div>
                   <div className="investor-img">
                   <img src={InvestorsAjay} alt="" />
                   </div>                   
                  </Slider>
                </div>
              </div>
            </div>
             </section>  
        )
    }
}