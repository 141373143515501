import React from 'react';
import { Link } from 'react-router-dom';


import videoCall from "../../assets/dust/video-call.svg";
import print from "../../assets/dust/print.svg";
import { changeDayString } from '../../constants/app-constants';

export default function Doctors({ doctors, consultType, sId }) {
  const displayDoctors = () => {
    let docs;
    if (!doctors) return (<div className="mhc-Loding"><img src="../loader.gif" alt="loader" /></div>);

    if (consultType === "0") docs = doctors;
    if (consultType === "1") docs = doctors.filter((doc) => doc.is_active_vc == 1);
    if (consultType === "2") docs = doctors.filter((doc) => doc.is_active_opd == 1);

    return docs.map((item) => {
      return (
        <div className="col-md-6 col-lg-6 col-xl-4 mb-4" key={item.id}>
          <div className="mhc-dector-card">
            <div className="row">
              <div className="col-9">
                <div className="d-flex align-items-center">
                  <div className="img-holder">
                    <img src={item.profile_image}></img>
                  </div>
                  <div className="ms-3">
                    <p className="m-0 fw-bolder  text-secondary text-tittle">
                      {item.name}
                    </p>
                    <p className="text-secondary sub-text">
                      {item.designation} <br />
                      Exp. {item.experience} Yrs
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-3 text-end">
                <ul className="list-unstyled mb-0 d-flex justify-content-end">
                  {item.is_active_vc === 1 && (
                    <li>
                      <img src={videoCall} className="icon me-3"></img>
                    </li>
                  )}
                  {item.is_active_opd === 1 && (
                    <li>
                      <img src={print} className="icon"></img>
                    </li>
                  )}
                </ul>
              </div>
            </div>

            <div className="d-flex justify-content-between align-items-center">
              <div>
                <ul className="list-unstyled d-flex flex-wrap mb-0 text-capitalize fs-6">
                  {
                    (item.availability && item.availability.length > 0) && (
                      <li className="px-1">
                        <span className="text-secondary  me-2">Available</span>
                      </li>
                    )
                  }
                  {
                    item.availability.map((day, index) => {
                      return (
                        <li className="px-1" key={day}>
                          <span className="text-primary fw-bolder">{changeDayString(day)}{((index + 1) !== item.availability.length) && ', '}</span>
                        </li>
                      );
                    })
                  }
                </ul>
              </div>
              <div>
                {
                  (item.availability && item.availability.length > 0) ? (
                    <Link to={`/doctor-profile/${item.id}/${item.hospital.id}/${sId}/${consultType}`}
                      className="btn btn-outline-primary rounded-1">Book</Link>
                  ) : (
                    <button className="btn btn-outline-primary rounded-1" disabled>Book</button>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      );
    });
  };
  return displayDoctors()
}