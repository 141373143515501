import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { indianPincode, vendorCities } from "../../actions/get-calls";

import countries from "../../assets/data/countries.json";
import { setPatientDetailReducer } from "../../redux/slice/patient-detail";

const PatientDetails = ({
  setCurrentDeliveryDetail,
  setIsDeliveryDetailAdded,
  setCurrentCityId
}) => {
  const dispatch = useDispatch();
  const vendorId = useSelector((state) => state.vendor.value);
  const locationData = useSelector((state) => state.location.value);
  const selectedMainUser = useSelector((state) => state.selectedUser.value);
  const patientDetailLocal = useSelector((state) => state.patientDetail.value);

  const [allState, setAllState] = useState(null);
  const [allCity, setAllCity] = useState(null);
  const [isBlocked, setIsBlocked] = useState(false);
  const [pincodeChecking, setPincodeChecking] = useState(false);
  const [serviceable, setServiceable] = useState(false);
  const [selectedVendorServicable, setSelectedVendorServicable] = useState(false);
  const [allVendorCities, setAllVendorCities] = useState(null);
  const [saveForFuture, setSaveForFuture] = useState(false);

  const [deliveryAddress, setDeliveryAddress] = useState({
    address1: "",
    address2: "",
    country: "India",
    state: "Delhi",
    city: "New Delhi",
    pincode: "",
  });

  useEffect(async () => {
    if (vendorId) {
      const _selectedVendor = await vendorCities(vendorId);
      if (_selectedVendor && _selectedVendor.data) {
        setAllVendorCities(_selectedVendor.data.data);
      }
    }

    const _selectedVendorServicable = await indianPincode(locationData.pin_code, vendorId);
    if (_selectedVendorServicable) {
      setSelectedVendorServicable(_selectedVendorServicable.status);
    }
  }, [vendorId, locationData]);

  useEffect(() => {
    if (selectedMainUser) {
      let preFilledData = {
        address1: selectedMainUser.address,
        address2: selectedMainUser.address2,
        country: selectedMainUser.country_name,
        state: selectedMainUser.state_name,
        city: selectedMainUser.city_name,
        pincode: selectedMainUser.pin_code ? selectedMainUser.pin_code : locationData ? locationData.pin_code : 0,
      };

      if (patientDetailLocal) {
        preFilledData = {
          address1: patientDetailLocal.address1,
          address2: patientDetailLocal.address2,
          country: patientDetailLocal.country,
          state: patientDetailLocal.state,
          city: patientDetailLocal.city,
          pincode: patientDetailLocal.pincode ? patientDetailLocal.pincode : locationData ? locationData.pin_code : 0,
        };

        setSaveForFuture(true);
      }

      setDeliveryAddress(preFilledData);

      if (preFilledData.city) {
        convertCityNameToCityId(preFilledData.city);
      }
    }
  }, [selectedMainUser]);

  useEffect(() => {
    setCurrentDeliveryDetail(deliveryAddress);
    if (deliveryAddress.city) {
      convertCityNameToCityId(deliveryAddress.city);
    }
  }, [deliveryAddress])

  useEffect(() => {
    if (saveForFuture) {
      dispatch(
        setPatientDetailReducer({
          address1: deliveryAddress.address1,
          address2: deliveryAddress.address2,
          country: deliveryAddress.country,
          state: deliveryAddress.state,
          city: deliveryAddress.city,
          pincode: deliveryAddress.pincode
        })
      );
    }
  }, [saveForFuture])

  useEffect(async () => {
    if (deliveryAddress.pincode && deliveryAddress.pincode.toString().length === 6) {
      setPincodeChecking(true);
      const _detail = await indianPincode(deliveryAddress.pincode, null);
      if (_detail && _detail.data) {
        setServiceable(_detail.data.status);
      }
      setPincodeChecking(false);
    }
  }, [deliveryAddress.pincode]);

  useEffect(() => {
    if (countries) {
      let countryIndex = countries.map(x => x.n).indexOf(deliveryAddress.country);
      setAllState(countries[countryIndex]['s']);
    }

    setIsDeliveryDetailAdded(false);
  }, [deliveryAddress.country])

  useEffect(() => {
    if (allState) {
      let countryIndex = countries.map(e => e.n).indexOf(deliveryAddress.country);
      let stateIndex = allState.map(e => e.n).indexOf(deliveryAddress.state);
      setAllCity(countries[countryIndex]['s'][stateIndex]['c']);
    }

    setIsDeliveryDetailAdded(false);
  }, [allState, deliveryAddress.state])

  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setDeliveryAddress({ ...deliveryAddress, [name]: value });
    setIsDeliveryDetailAdded(false);
  }

  function convertCityNameToCityId(city) {
    if (allVendorCities) {
      let cityIndex = allVendorCities.findIndex(c => c.center_city === city);
      if (cityIndex > 0) {
        setCurrentCityId(allVendorCities[cityIndex].city_id);
      }
    }
  }

  return (
    <React.Fragment>
      <div className="mhc-register-form">
        <div className="row">
          <div className="col-lg-12">
            <div className="form-floating mb-4">
              <input type="text" className="form-control" placeholder="Address Line 1" value={deliveryAddress.address1} onChange={handleInput} name="address1" />
              <label htmlFor="AddressLine">Address Line 1 <sup>*</sup></label>
            </div>
          </div>

          <div className="col-lg-12">
            <div className="form-floating mb-4">
              <input type="text" className="form-control" placeholder="Address Line 2" value={deliveryAddress.address2} onChange={handleInput} name="address2" />
              <label htmlFor="AddressLine">Address Line 2</label>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="mhc-selectbox mb-4">
              <select
                name="country"
                required
                className="form-select"
                value={deliveryAddress.country}
                disabled
              >
                {
                  countries && countries.map((item) => {
                    const { n } = item;
                    return (<option key={n} value={n}>{n}</option>);
                  })
                }
              </select>
              <label htmlFor="floatingSelect">Country</label>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="mhc-selectbox mb-4">
              <select
                name="state"
                required
                className="form-select"
                onChange={handleInput}
                value={deliveryAddress.state}>
                {
                  allState && allState.map((item) => {
                    const { n } = item;
                    return (<option key={n} value={n}>{n}</option>);
                  })
                }
              </select>
              <label htmlFor="floatingSelect">State</label>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="mhc-selectbox mb-4">
              <select
                name="city"
                className="form-select"
                required
                value={deliveryAddress.city}
                onChange={handleInput}>
                {
                  allCity && allCity.map((item) => {
                    return (<option key={item} value={item}>{item}</option>);
                  })
                }
              </select>
              <label htmlFor="floatingSelect">City</label>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="form-floating position-relative">
              <input
                type="text"
                className="form-control"
                placeholder="Enter pin code"
                maxLength="6" max="6" min="6"
                value={deliveryAddress.pincode}
                onChange={(e) => handleInput(e)} name="pincode"
              />
              {pincodeChecking && (
                <div className="ajax-loader">
                  <img src="../loader.gif" className="w-100" alt="Loding Image" />
                </div>
              )}
              <label htmlFor="PinCode">Pin Code <sup>*</sup></label>
            </div>
            {
              (!pincodeChecking && deliveryAddress.pincode.toString().length !== 0) && (<p className="mt-1 m-0 text-primary">Your location is {serviceable ? 'servicable' : 'not servicable'}.</p>)
            }
          </div>
        </div>

        <div className="row mt-3 justify-content-end align-items-center">
          <div className="col-auto">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                autoComplete="off"
                checked={saveForFuture}
                onChange={(e) => { setSaveForFuture(e.target.checked); }}
              />
              <label className="form-check-label d-block d-sm-inline">Save For Future</label>
            </div>
          </div>
          <div className="col-auto">
            <button className="btn btn-outline-primary rounded-1" onClick={(e) => {
              setIsDeliveryDetailAdded(true);
              convertCityNameToCityId(deliveryAddress.city);
              if (!saveForFuture) dispatch(setPatientDetailReducer(null));
            }} disabled={!deliveryAddress.address1 || !deliveryAddress.state || !deliveryAddress.city || !deliveryAddress.pincode || !serviceable}>Continue</button>
          </div>
        </div>
      </div>

    </React.Fragment >
  )
}

export default PatientDetails