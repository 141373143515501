import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { verifyMobileOTP } from "../../actions/post-call";
import { setCallTokenReducer } from "../../redux/slice/call-token";

const VerifyRegister = ({ mobile, setOtpVerified }) => {

  const dispatch = useDispatch();
  const [otp, setOtp] = useState();

  const verifyOTPhandle = () => {
    verifyMobileOTP({ isd_code: "91", otp: otp, uid: mobile })
      .then(function (response) {
        let res = response.data;
        if (res && res.errors && res.errors.length > 0) {
          return toast.error(res.errors[0].message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false
          });
        }

        dispatch(setCallTokenReducer(response.headers.authorization));
        setOtpVerified(true);
      })
      .catch(function (err) { });
  };
  return (
    <React.Fragment>
      <form>
        <div className="col-lg-7 mt-3 mt-lg-0">
          <h5>Verify OTP sent on {mobile}</h5>
          <div className="d-flex justify-content-end flex-column flex-lg-row mt-3">
            <div className="form-floating">
              <input
                type="text"
                className="form-control"
                defaultValue=""
                id="otp"
                required
                placeholder="Enter otp"
                value={otp}
                maxLength="4"
                onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }}
                onChange={(e) => setOtp(e.target.value)}
              />
              <label htmlFor="name">
                OTP <sup>*</sup>
              </label>
            </div>
            <div className="ms-0 ms-lg-3   mt-3 mt-lg-0">
              <button
                className="btn btn-outline-primary rounded-1 text-nowrap"
                type="submit"
                onClick={verifyOTPhandle()}
              >
                Verify OTP
              </button>
            </div>
          </div>
        </div>
      </form>

      <ToastContainer />
    </React.Fragment>
  );
};

export default VerifyRegister;
