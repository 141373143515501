import React from "react";
import Slider from "react-slick";
import Shyatto from "../../assets/images/shyatto.webp"
import AneeshNair from "../../assets/images/Aneesh-Nair.webp"
import DivyaLaroyia from "../../assets/images/Divya-Laroyia.webp"
import KaranPalSingh from "../../assets/images/Karan-Pal-Singh.webp"
import nikhilVora from "../../assets/images/nikhil-vora.jpg"
import Hemchandra from "../../assets/images/Hemchandra-1.jpeg"
const settings ={
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 1000,
    responsive: [
        {
            breakpoint: 1200,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
           
            }
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
           
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
           
            }
          },
          {
            breakpoint: 576,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
           
            }
          },
          
    ]
    
  }
export default class OurBoard extends React.Component{
    render(){
        return(
            <section className="about-events">
            <div className="container">
            <h1 class="text-left text-lg-center fs30">Our Board</h1>
            <div className="row mt-4 justify-content-center">
             <div className="col-xxl-12 col-xl-12 ">
             <Slider {...settings} >
                   <div>
                     <figure class="figure">
                     <img src={Shyatto} />
                       <figcaption class="figure-caption"><strong>Shyatto Raha</strong><br />
                            <small>Founder & CEO</small> </figcaption>
                     </figure>        
                   </div>
                   <div>
                     <figure class="figure">
                     <img src={AneeshNair} />
                       <figcaption class="figure-caption"><strong>Aneesh Nair</strong><br />
                       <small>Co-founder & CIO</small> </figcaption>
                     </figure>        
                   </div>
                   <div>
                     <figure class="figure">
                     <img src={DivyaLaroyia} />
                       <figcaption class="figure-caption"><strong>Divya Laroyia</strong><br />
                       <small>Co-founder & Chief Product 
                           Officer</small> </figcaption>
                     </figure>        
                   </div>
               
                   <div>
                     <figure class="figure">
                     <img src={KaranPalSingh} />
                       <figcaption class="figure-caption"><strong>Karan Pal Singh
                            </strong><br />
                       <small>Founder and Director, Hunch 
                               Ventures</small> </figcaption>
                     </figure>        
                   </div>
                   <div>
                     <figure class="figure">
                     <img src={nikhilVora} />
                       <figcaption class="figure-caption"><strong>Nikhil Vora
                            </strong><br />
                       <small>Founder and CEO, Sixth Sense Ventures</small> </figcaption>
                     </figure>        
                   </div>
                   <div>
                     <figure class="figure">
                     <img src={Hemchandra} />
                       <figcaption class="figure-caption"><strong>Hemchandra Javeri
                            </strong><br />
                       <small>Non-Executive Director</small> </figcaption>
                     </figure>        
                   </div>
                  
                   </Slider>
             </div>
            </div>
           
            </div>
         </section>
        )
    }
}