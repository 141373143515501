import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import { hospitalByCity } from "../../actions/get-calls";
import { searchTextAPI } from "../../actions/post-call";

const HospitalTab = ({
  keyword
}) => {
  const locationData = useSelector((state) => state.location.value);
  const [filteredHospitals, setFilteredHospitals] = useState();

  useEffect(() => {
    if (locationData) {
      getHospital();
    }
  }, [locationData]);

  useEffect(() => {
    if (keyword !== "") {
      searchHospital();
    } else {
      getHospital();
    }
  }, [keyword]);

  function getHospital() {
    hospitalByCity(locationData.latitude, locationData.longitude)
      .then(function (response) {
        let res = response.data;
        setFilteredHospitals(res.cities);
      })
      .catch(function (err) { });
  }

  function searchHospital() {
    if (keyword === "" || keyword.length < 3) return false;
    const delayDebounceFn = setTimeout(async () => {
      setFilteredHospitals(undefined);
      const searchedData = await searchTextAPI(keyword, 'hospitals');

      if (searchedData.data.status === 200) {
        setFilteredHospitals(searchedData.data.hospitals);
      }
    }, 500)

    return () => clearTimeout(delayDebounceFn);
  }

  return (
    <>
      <div className="row mb-4 hospital-tab">
        {filteredHospitals === undefined ? (
          <div className="mhc-Loding text-center my-5">
            <img src="../loader.gif" alt="loader" />
          </div>
        ) : (
          filteredHospitals.map((item, index) => {
            return (
              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 mb-4" key={index}>
                <Link
                  className="text-decoration-none"
                  to={`/hospital-consult/${!item.hospitals ? item.id : item.hospitals[0].id}`}>
                  <Card className="hospital-card">
                    <div className="row pt-3">
                      <div className="col-12 d-flex justify-content-center align-items-center">
                        <Card.Img
                          variant="top"
                          className="hospital-img"
                          src={!item.hospitals ? item.images : item.hospitals[0].image}
                          alt={!item.hospitals ? item.display_name : item.hospitals[0].name}
                        />
                      </div>
                    </div>
                    <Card.Body>
                      <div className="row">
                        <div className="col-12 d-flex justify-content-center">
                          <Card.Text>
                            <div className="d-flex justify-content-center text-center">
                              <span className="text-dark">
                                {!item.hospitals ? item.display_name : item.hospitals[0].name} <br />
                                <span className="text-secondary">
                                  {!item.hospitals ? item.address : item.name}
                                </span>
                              </span>
                            </div>
                          </Card.Text>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Link>
              </div>
            );
          })
        )}
      </div>
    </>
  );
};
export default HospitalTab;
