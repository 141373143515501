import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { registerOTP } from "../../actions/post-call";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DatePicker from "react-datepicker";
import countries from "../../assets/data/countries.json";
import { dateFormatIntoDDMMYYYY, genderList, maritalStatusList } from "../../constants/app-constants";

const NewUserRegister = ({ setVerifyOTP, mobile }) => {
  const [DOB, setDOB] = useState(new Date());
  const [allState, setAllState] = useState(null);
  const [allCity, setAllCity] = useState(null);
  const [isTerm, setIsTerm] = useState(false);

  useEffect(() => {
    formik.setFieldValue('mobile', mobile);
  }, [mobile])

  const formik = useFormik({
    initialValues: {
      fullName: "",
      mobile: "",
      emergencyContact: "",
      email: "",
      addressOne: "",
      addressTwo: "",
      pincode: "",
      dob: new Date(),
      gender: "0",
      maritalStatus: "0",
      country: "India",
      state: "",
      city: "",
    },

    onSubmit: (values, helpers) => {
      let payload = {
        first_name: values.fullName,
        gender: parseInt(values.gender),
        dob: dateFormatIntoDDMMYYYY(DOB),
        isd_code: "91",
        mobile_no: values.mobile,
        marital_status: parseInt(values.maritalStatus),
        emergency_contact: values.emergencyContact,
        email: values.email,
        address_1: values.addressOne,
        address_2: values.addressTwo,
        country: values.country,
        state: values.state,
        city: values.city,
        pin_code: values.pincode,
      };

      if (values.fullName === '') {
        return showError('Full name is mandatory!');
      } else if (values.mobile === '') {
        return showError('Mobile is mandatory!');
      } else if (values.email === '') {
        return showError('Email is mandatory!');
      } else if (values.dob === '') {
        return showError('DOB is mandatory!');
      } else if (values.gender === '') {
        return showError('Gender is mandatory!');
      } else if (values.maritalStatus === '') {
        return showError('Marital Status is mandatory!');
      } else if (values.addressOne === '') {
        return showError('Address 1 is mandatory!');
      } else if (values.addressTwo === '') {
        return showError('Address 2 is mandatory!');
      } else if (values.country === '') {
        return showError('Country is mandatory!');
      } else if (values.state === '') {
        return showError('State is mandatory!');
      } else if (values.city === '') {
        return showError('City is mandatory!');
      }

      registerOTP(payload)
        .then(function (response) {
          let res = response.data;

          if (res && res.errors) {
            res.errors.map((e) => {
              return showError(e.message);
            })
            return false;
          }

          setVerifyOTP(true);
          toast.success("Success registration! Please enter OTP to login");
        })
        .catch(function (err) { });
    },
  });
  useEffect(()=>{
    stateList('India')
  },[])
  function showError(str) {
    toast.error(str, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false
    });
  }

  function stateList(country) {
    // formik.setFieldValue("country", e.target.value);
    if (countries && country) {
      let countryIndex = countries.map(e => e.n).indexOf(country);
      setAllState(countries[countryIndex]['s']);
    }
  }

  function cityList(e) {
    formik.setFieldValue("state", e.target.value);
    if (countries && allState && e.target.value) {
      let countryIndex = countries.map(e => e.n).indexOf(formik.values.country);
      let stateIndex = allState.map(e => e.n).indexOf(e.target.value);
      setAllCity(countries[countryIndex]['s'][stateIndex]['c']);
    }
  }

  function minDate() {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();

    if (dd < 10) {
      dd = '0' + dd
    }

    if (mm < 10) {
      mm = '0' + mm
    }

    today = dd + '-' + mm + '-' + yyyy;
    return today;
  }

  return (
    <React.Fragment>
      <form onSubmit={formik.handleSubmit}>
        <div className="mhc-register-form">
          <div className="row">
            <div className="col-lg-6">
              <div className="form-floating">
                <input
                  type="text"
                  className="form-control"
                  placeholder="mobile"
                  name="mobile"
                  maxLength="10"
                  required
                  onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }}
                  onChange={formik.handleChange}
                  value={formik.values.mobile}
                  disabled
                />
                <label htmlFor="name">Mobile <sup>*</sup></label>
              </div>
            </div>
            <div className="col-lg-6 mt-3 mt-lg-0">
              <p className="fs-13 text-primary mb-0">
                Sorry, this number is not registered with us. Please register to continue
              </p>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-12">
              <h1 className="fs-5 mb-4 text-center text-lg-start">
                Patient Details
              </h1>
            </div>
            <div className="col-lg-6">
              <div className="form-floating mb-4">
                <input
                  type="text"
                  className="form-control"
                  autoComplete="off"
                  placeholder="fullName"
                  name="fullName"
                  required
                  onChange={formik.handleChange}
                  value={formik.values.fullName}
                />
                <label htmlFor="fullName">
                  Full Name <sup>*</sup>
                </label>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="form-floating mb-4">
                <input
                  type="text"
                  className="form-control"
                  maxLength="10"
                  required
                  placeholder="mobile"
                  name="mobile"
                  onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }}
                  onChange={formik.handleChange}
                  value={formik.values.mobile}
                />
                <label htmlFor="mobile">
                  Mobile <sup>*</sup>
                </label>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="form-floating mb-4">
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder="email"
                  required
                  name="email"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                />
                <label htmlFor="email">
                  Email <sup>*</sup>
                </label>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="form-floating mhc-dob date mb-4">
                <DatePicker
                  calendarClassName="mhc-input-date"
                  dateFormat="dd/MM/yy"
                  selected={DOB}
                  closeOnScroll={true}
                  required
                  minDate='-1d'
                  onChange={(date) => setDOB(date)}
                  calendarStartDay={1}
                  formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 3)}
                  showMonthDropdown
                  showYearDropdown
                  adjustDateOnChange
                />
                <i className="mhc-icon icon-calendar"></i>
                <label htmlFor="Dob" className="dob">DOB <sup>*</sup></label>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="mhc-selectbox mb-4">
                <select
                  name="gender"
                  className="form-select"
                  required
                  onChange={formik.handleChange}
                  value={formik.values.gender}>
                  {
                    genderList && genderList.map((item, index) => {
                      return (<option key={index} value={index}>{item}</option>);
                    })
                  }
                </select>
                <label htmlFor="floatingSelect">Gender <sup>*</sup></label>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="mhc-selectbox mb-4">
                <select
                  name="maritalStatus"
                  className="form-select"
                  required
                  onChange={formik.handleChange}
                  value={formik.values.maritalStatus}>
                  {
                    maritalStatusList && maritalStatusList.map((item, index) => {
                      return (<option key={index} value={index}>{item}</option>);
                    })
                  }
                </select>
                <label htmlFor="floatingSelect">Marital Status <sup>*</sup></label>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="form-floating mb-4">
                <input
                  type="text"
                  className="form-control"
                  id="emergencyContact"
                  placeholder="emergency Contact"
                  name="emergencyContact"
                  maxLength="10"
                  onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }}
                  onChange={formik.handleChange}
                  value={formik.values.emergencyContact}
                />
                <label htmlFor="emergencyContact">
                  Emergency Contact
                </label>
              </div>
            </div>

            <div className="col-12">
              <h1 className="fs-5 mb-4 text-center text-lg-start">Address</h1>
            </div>

            <div className="col-lg-12">
              <div className="form-floating mb-4">
                <input
                  type="text"
                  className="form-control"
                  id="addressOne"
                  required
                  placeholder="addressOne"
                  name="addressOne"
                  onChange={formik.handleChange}
                  value={formik.values.addressOne}
                />
                <label htmlFor="addressOne">
                  Address Line 1 <sup>*</sup>
                </label>
              </div>
            </div>

            <div className="col-lg-12">
              <div className="form-floating mb-4">
                <input
                  type="text"
                  className="form-control"
                  id="addressTwo"
                  placeholder="addressTwo"
                  name="addressTwo"
                  onChange={formik.handleChange}
                  value={formik.values.addressTwo}
                />
                <label htmlFor="addressTwo">
                  Address Line 2 <sup>*</sup>
                </label>
              </div>
            </div>

            <div className="col-lg-6">
              <select
                name="country"
                required
                className="form-select"
                onChange={(e) => { stateList(e.target.value) }}
                value={formik.values.country}>
                {
                  countries && countries.map((item) => {
                    const { n } = item;
                    return (<option key={n} value={n}>{n}</option>);
                  })
                }
              </select>
            </div>

            <div className="col-lg-6">
              <div className="mhc-selectbox mb-4">
                <select
                  name="state"
                  required
                  className="form-select"
                  onChange={(e) => { cityList(e) }}
                  value={formik.values.state}>
                  {
                    allState && allState.map((item) => {
                      const { n } = item;
                      return (<option key={n} value={n}>{n}</option>);
                    })
                  }
                </select>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="mhc-selectbox mb-4">
                <select
                  name="city"
                  className="form-select"
                  required
                  onChange={formik.handleChange}
                  value={formik.values.city}
                  placeholder='Please select the city'
                  >
                  {
                    allCity && allCity.map((item) => {
                      return (<option key={item} value={item}>{item}</option>);
                    })
                  }
                </select>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="form-floating mb-4">
                <input
                  type="text"
                  className="form-control"
                  id="pincode"
                  placeholder="pincode"
                  name="pincode"
                  maxLength="6"
                  onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }}
                  onChange={formik.handleChange}
                  value={formik.values.pincode}
                />
                <label htmlFor="pincode">
                  Pin Code
                </label>
              </div>
            </div>

            <div className="col-12">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="flexCheckDefault"
                  required
                  value={isTerm}
                  onChange={(e) => setIsTerm(e.target.checked)}
                />
                <label
                  className="form-check-label d-block d-sm-inline"
                  htmlFor="flexCheckDefault">
                  I have read and agree to the
                  <a className="text-primary text-decoration-none"> Terms and Conditions.</a>
                </label>
              </div>
            </div>

            <div className="col-12 text-end mt-3">
              <button className="btn btn-outline-primary rounded-1" type="submit" disabled={!isTerm}>Register</button>
            </div>
          </div>
          {/* <div className="row mt-4">
            <div className="col-lg-5">
              <p className="fs-13 text-primary mb-0">
                Sorry, this number is not registered with us. Please register to
                continue
              </p>
            </div>
          </div> */}
        </div>
      </form>
      <ToastContainer />
    </React.Fragment>
  );
};

export default NewUserRegister;
