import React from "react";
import Slider from "react-slick";
import PoorvaNandedkar from "../../assets/images/Poorva-Nandedkar.webp"
import RaviKumarVajja from "../../assets/images/Ravi-Kumar-Vajja.webp"
import DiwakarBhowmik from "../../assets/images/Diwakar-Bhowmik.webp"
import AnandMahalingam from "../../assets/images/Anand-Mahalingam.webp"
import tanuja from "../../assets/images/tanuja.jpg"

const settings ={
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 1000,
    responsive: [
        {
            breakpoint: 1200,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
           
            }
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
           
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
           
            }
          },
          {
            breakpoint: 576,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
           
            }
          },
          
    ]
    
  }
export default class OurTeam extends React.Component{
    render(){
        return(
<section className="about-events bg-white">
               <div className="container">
               <h1 class="text-left text-lg-center fs30">Our Team</h1>
               <div className="row mt-4 justify-content-center">
                <div className="col-xxl-12 col-xl-12 ">
                <Slider {...settings} >
                      <div>
                        <figure class="figure">
                        <img src={PoorvaNandedkar} />
                          <figcaption class="figure-caption"><strong>Dr Poorva Nandedkar</strong><br />
                               <small>AVP, Clinical Transformation</small> </figcaption>
                        </figure>        
                      </div>
                      <div>
                        <figure class="figure">
                        <img src={RaviKumarVajja} />
                          <figcaption class="figure-caption"><strong>Ravi Kumar Vajja</strong><br />
                          <small>AVP, Product & Platform</small> </figcaption>
                        </figure>        
                      </div>
                      <div>
                        <figure class="figure">
                        <img src={DiwakarBhowmik} />
                          <figcaption class="figure-caption"><strong>Diwakar Bhowmik</strong><br />
                          <small>VP, MyHealthcare Enterprise 
                          Application</small> </figcaption>
                        </figure>        
                      </div>
                  
                      <div>
                        <figure class="figure">
                        <img src={AnandMahalingam} />
                          <figcaption class="figure-caption"><strong>Dr Anand Mahalingam

                               </strong><br />
                          <small>VP, MyHealthcare AI</small> </figcaption>
                        </figure>        
                      </div>
                      <div>
                        <figure class="figure">
                        <img src={tanuja} />
                          <figcaption class="figure-caption"><strong>Tanuja Singh
                               </strong><br />
                          <small>VP, Product Delivery &amp; Customer Success</small> </figcaption>
                        </figure>        
                      </div>
                      </Slider>
                </div>
               </div>
              
               </div>
            </section>
        )
    }
}