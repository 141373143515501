import React from "react";
import { Link } from "react-router-dom";
const IProps = {
    url: '',
    chlidrenUrl: '',
    firstText: '',
    secondText: '',
    text: ''
}
class Breadcrumb extends React.Component {
    constructor(props = IProps) {
        super(props)
    }
    render() {
        return (
            <React.Fragment>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to={this.props.url}> Home</Link></li>
                        {this.props.firstText && (<li className="breadcrumb-item"><span>{this.props.firstText}</span></li>)}
                        {this.props.secondText && (<li className="breadcrumb-item"><span>{this.props.secondText}</span></li>)}
                        <li className="breadcrumb-item active" aria-current="page" >
                            <a onClick={(e) => { e.preventDefault() }}>{this.props.text}</a>
                        </li>
                    </ol>
                </nav>
            </React.Fragment>
        )
    }
}

export default Breadcrumb