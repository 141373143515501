import React, { useCallback, useEffect , useState} from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { fetchClinicList} from '../../actions/get-calls.js'
const ClinicsTab = ({ clinicList }) => {
  const [specialities,setSpecialities] = useState([])
  const [loader,setLoader] = useState(false)
  const getClinicsList = useCallback(async ()=>{
    setLoader(true);
   const response =  await fetchClinicList();
   console.log('response: ', response);
   setLoader(false)
   setSpecialities(response.data.specialities)
  },[])
  useEffect(()=>{
    getClinicsList();
  },[getClinicsList])
  return (
    <>
      <div className="mb-4 hospital-tab">
        <div className="row mb-4 hospital-tab">
          {loader ? (
            <div className="mhc-Loding text-center my-5">
              <img src="../loader.gif" alt="loader" />
            </div>
          ) : (
            specialities.map((clinic) => {
              return (
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 mb-4" key={clinic.id}>
                  <Link
                    className="text-decoration-none"
                    to={`/booking-consult/consult-doctor/15/${clinic.id}`}
                  >
                    <Card className="hospital-card">
                      <div className="row pt-3">
                        <div className="col-12 d-flex justify-content-center align-items-center">
                          <Card.Img
                            variant="top"
                            className="hospital-img"
                            src={clinic.icon}
                            alt={clinic.name}
                          />
                        </div>
                      </div>
                      <Card.Body>
                        <div className="row">
                          <div className="col-12 d-flex justify-content-center">
                            <Card.Text>
                              <div className="d-flex justify-content-center text-center">
                                <span className="text-dark">
                                  {clinic.name}
                                  {/* <br />{" "}
                                  <span className="text-secondary">
                                    Gurugram
                                  </span> */}
                                </span>
                              </div>
                            </Card.Text>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Link>
                </div>
              );
            })
          )}
        </div>
      </div>
    </>
  );
};

export default ClinicsTab;