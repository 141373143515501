import React, { useEffect, useLayoutEffect, useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { useLocation } from "react-router-dom";

import Breadcrumb from "../../component/breadcrumb/index";
import Specialities from "../specialities/index";
import HospitalTab from "../hospitals/hospitalTab";
import ClinicsTab from "../clinics/clinicsTab";
import DoctorsTabs from "../DoctorsTab/doctorsTab";

import SearchTextWidget from "../../component/search/search-text-widget";
import BreadcrumbWithUrl from "../../component/breadcrumb/breadcrumb-with-url";

function BookingConsult() {

  const location = useLocation();
  const [callingType, setCallingType] = useState("hospitals");
  const [clinicList, setClinicList] = useState();

  const [keyword, setKeyword] = useState("");

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });

  useEffect(async () => {
    let cType = location.pathname === "/booking-consult" ? "hospitals"
      : location.pathname === "/booking-consult/hospitals" ? "hospitals"
        : location.pathname === "/booking-consult/specialities" ? "specialities"
          : location.pathname === "/booking-consult/clinics" ? "clinics"
            : location.pathname === "/booking-consult/doctors" ? "doctors" : "hospitals";

    setCallingType(cType);
  }, []);

  const breadCrumbData = [
    { type: 1, url: '/', name: 'Home' },
    { type: 2, url: '', name: 'Book Consult' },
    { type: 3, url: '', name: callingType }
  ];

  return (
    <React.Fragment>
      <section>
        <div className="container">
          <BreadcrumbWithUrl breadcrumbs={breadCrumbData} />

          <div className="row">
            <div className="col-lg-6 ">
              <h1 className="fs-4 mb-1">Book Consult</h1>
              <p className="m-0 fs-6">
                Consult with top doctors across specialities
              </p>
            </div>
            <div className="col-lg-6 mt-4 mt-lg-0">
              <SearchTextWidget
                placeHolder={`Search ${callingType}`}
                btnDisplay={true}
                setKeyword={setKeyword} />
            </div>
          </div>
          <div className="mhc-tabs my-4">
            <Tabs activeKey={callingType}
              onSelect={(e) => {
                setCallingType(e);
                setKeyword("");
                window.history.replaceState(
                  {},
                  document.getElementsByTagName('title')[0].innerHTML,
                  `/booking-consult/${e}`
                );
              }}
              className="mb-3">
              <Tab eventKey="hospitals" title="Hospitals">
                <div className="mt-2">
                  <HospitalTab keyword={keyword} />
                </div>
              </Tab>

              <Tab eventKey="specialities" title="Specialities">
                <div className="mt-2">
                  <Specialities keyword={keyword} />
                </div>
              </Tab>

              <Tab eventKey="clinics" title="Clinics">
                <div className="mt-2">
                  <ClinicsTab setClinicList={setClinicList} />
                </div>
              </Tab>

              <Tab eventKey="doctors" title="Doctors">
                <DoctorsTabs />
              </Tab>
            </Tabs>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default BookingConsult;
