import React, { useEffect, useLayoutEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import Breadcrumb from "../../component/breadcrumb";
import { getHomecareCategory } from "../../actions/get-calls";
import { encodeString } from "../../constants/app-constants";
import BreadcrumbWithUrl from "../../component/breadcrumb/breadcrumb-with-url";

const HomecareService = () => {

  const [category, setCategory] = useState(null);

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });

  useEffect(() => {
    async function fetchData() {
      const response = await getHomecareCategory();
      let res = response.data;
      if (res && res.homecare_category) {
        setCategory(res.homecare_category);
      }
    }
    fetchData();
  }, []);

  const breadCrumbData = [
    { type: 1, url: '/', name: 'Home' },
    { type: 3, url: '', name: 'Homecare Services' }
  ];

  return (
    <React.Fragment>
      <section>
        <div className="container mb-2 homecare-services">
          <BreadcrumbWithUrl breadcrumbs={breadCrumbData} />
          <div className="row">
            <div className="col-lg-6 ">
              <h1 className="fs-4 mb-1">Homecare Services</h1>
            </div>
          </div>
          <div className="row mt-4">
            {!category ? (
              <div className="mhc-Loding my-5 text-center">
                <img src="loader.gif" alt="loader" />
              </div>
            ) : (
              category.map((item, index) => {
                return (
                  <>
                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 col-12 mb-4" key={index}>
                      <Card className="homecare-service-card">
                        <div className="row pt-3">
                          <div className="col-12 img-holder">
                            <Card.Img
                              variant="top"
                              className="image"
                              src={item.icon}
                              alt={item.name}
                            />
                          </div>
                        </div>
                        <Card.Body>
                          <div className="row">
                            <div className="col-12 d-flex justify-content-center">
                              <div className="d-flex justify-content-center text-center">
                                <span className="text-dark">{item.name}</span>
                              </div>
                            </div>
                          </div>
                          <div className="row mt-2">
                            <div className="col-12 d-flex justify-content-center">
                              <Link
                                className="nav-link "
                                aria-current="page"
                                to={`/homecare-service-category/${encodeString(item.name)}/${item.id}`}>
                                <button className="btn btn-primary rounded-1 px-4">View</button>
                              </Link>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  </>
                );
              })
            )}
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default HomecareService;
