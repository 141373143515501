import React, { useState } from "react";
import { Container, Row, Col, Nav, Tab, Accordion   } from "react-bootstrap";
import Breadcrumb from "../../component/breadcrumb";

function FAQ (){
    const [key, setKey] = useState('AccessingApp');

    const handleOnSelect = (kk)=>{
console.log(kk)
    setKey(kk)
    }
        return(
            <React.Fragment>
               <Container>
               <Breadcrumb url="/" chlidrenUrl="" text="Faq" />
               <Tab.Container id="left-tabs-example" defaultActiveKey="first">
      <Row>
        <Col lg={3}>
            <div className="faq-container">
          <Nav variant="pills" className="flex-column "
              activeKey={key}
                onSelect={(k) => handleOnSelect(k)}
          >
            <Nav.Item>
              <Nav.Link eventKey="AccessingApp"  active={key==="AccessingApp"? true:false}>
              Accessing the App
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="PatientRegistration" active={key==="PatientRegistration"? true:false} >
              Patient Registration
              </Nav.Link>
            </Nav.Item>   
            <Nav.Item>
            <Nav.Link eventKey="DoctorSlot" active={key==="DoctorSlot"? true:false} >
            Doctor’s Slot
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
            <Nav.Link eventKey="AppointmentBooking" active={key==="AppointmentBooking"? true:false} >
            Appointment Booking
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
            <Nav.Link eventKey="ConsultCharges" active={key==="ConsultCharges"? true:false} >
            Consult Charges
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
            <Nav.Link eventKey="DuringVirtualConsult" active={key==="DuringVirtualConsult"? true:false} >
            During Virtual Consult
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
            <Nav.Link eventKey="MHVCPlatform" active={key==="MHVCPlatform"? true:false} >
            MHVC Platform
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
            <Nav.Link eventKey="TeleMedicineGuidelines" active={key==="TeleMedicineGuidelines"? true:false} >
            Tele-Medicine Guidelines
              </Nav.Link>
            </Nav.Item>
          </Nav>
          </div>
        </Col>
        <Col lg={9}>
          <Tab.Content>
            <Tab.Pane eventKey="AccessingApp" className={key==="AccessingApp"? "active show":""}>
            <Accordion defaultActiveKey="0" flush className="faq-container">
      <Accordion.Item eventKey="0">
        <Accordion.Header>Is MHVC doctor’s app available on Play Store?    </Accordion.Header>
        <Accordion.Body>
        <p>Yes, the app is available by the name “<strong>MH Doctor</strong>” for doctors on Play Store for android devices and on App Store for iOS devices. Patient app is also available by the name of “<strong>MyHealthcare</strong>” on Play Store for android devices and on App Store<br />
for iOS devices.</p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>How do I access the app?     </Accordion.Header>
        <Accordion.Body>
       <p>After installing the app, you are required to enter your registered mobile number and OTP to complete the authentication and access the app. Please refer the doctors’ user manual for details.</p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>How would the patient download the app? </Accordion.Header>
        <Accordion.Body>
        <ul>
            <li>The patient app by the name “MyHealthcare” is available on Play Store for<br />
            android users and on App Store for iOS users.</li>
            <li>Hospital call centre team is also trained to inform the patients about MHVC.</li>
            <li>Patients will also receive a link to download the app in each appointment<br />
            confirmation SMS.</li>
            </ul>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header>Instead of downloading it on the phone, can we download it on our Macs/windows and use the app? </Accordion.Header>
        <Accordion.Body>
       <p>As of now, the platform is available only as android and iOS mobile app. All efforts are made to make a web version available as soon as possible.</p>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
            </Tab.Pane>
            <Tab.Pane eventKey="PatientRegistration" className={key==="PatientRegistration"? "active show":""}>
            <Accordion defaultActiveKey="4" flush className="faq-container">
      <Accordion.Item eventKey="4">
        <Accordion.Header>How is a new patient registered?    </Accordion.Header>
        <Accordion.Body>
        <p>MyHealthcare patient app has the provision to register a new patient or to add a family member by simple OTP based authentication.</p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="5">
        <Accordion.Header>How do I identify the patient?      </Accordion.Header>
        <Accordion.Body>
       <p>You would explicitly ask the age of each patient, and if there is any doubt, seek age proof. Where the patient is a minor, after confirming the age, you will proceed with a virtual consultation only if the minor is consulting along-with an adult (guardian or relative) whose identity will also be ascertained by you. Please refer Virtual Consult Terms & Conditions for doctors for other details.</p>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
            </Tab.Pane>
            <Tab.Pane eventKey="DoctorSlot" className={key==="DoctorSlot"? "active show":""}>
            <Accordion defaultActiveKey="5" flush className="faq-container">
      <Accordion.Item eventKey="5">
        <Accordion.Header>Whom do I contact to create my time slots?  </Accordion.Header>
        <Accordion.Body>
        <p>You may reach out to your front office or call centre team to create the slots as per your preference.</p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="6">
        <Accordion.Header>Are the time slots same as existing or can I modify?   </Accordion.Header>
        <Accordion.Body>
       <p>You are free to modify the time slots as per your availability. The same must be communicated to the front office or call centre team</p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="7">
        <Accordion.Header>What is the process of creating and blocking of slots?  </Accordion.Header>
        <Accordion.Body>
       <p>You may reach out to your front office or call-centre team to create the slots as per your preference as well as for blocking it for any reason.</p>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
            </Tab.Pane>
            <Tab.Pane eventKey="AppointmentBooking" className={key==="AppointmentBooking"? "active show":""}>
            <Accordion defaultActiveKey="8" flush className="faq-container">
      <Accordion.Item eventKey="8">
        <Accordion.Header>How would the patient book the appointment?  </Accordion.Header>
        <Accordion.Body>
        <ul>
            <li>A patient can book the appointment by<br />
            a) Dialling the hospital’s call-centre number<br />
            b) Using MyHealthcare patient app.</li>
            <li>Additionally, doctor’s app has the provision to book the appointment on<br />
            patient’s behalf.</li>
            </ul>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="9">
        <Accordion.Header>What do I do if I am running late for the appointment? How do I inform the patient?   </Accordion.Header>
        <Accordion.Body>
       <p>A leeway of late consult is already conveyed to the patient in their appointment confirmation message to consider any emergency/ continuing task that the doctor might be busy with. If at all the delay is beyond that, you need to inform the hospital call-centre or respective front office team to communicate with the patients.</p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="10">
        <Accordion.Header>Will I get any alert before the appointment as a reminder?             </Accordion.Header>
        <Accordion.Body>
       <ul>
        <li>Yes, you will receive a SMS reminder 15 min before appointment time.</li>
        <li>There is also&nbsp; an in-app notification sent 15 minutes before to remind you of an upcoming appointment.</li>
        </ul>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="11">
        <Accordion.Header> What do I do if I have to cancel the appointments owing to any emergency?     </Accordion.Header>
        <Accordion.Body>
       <p> You are advised to inform the call-centre or front office team to block your schedule and manage the appointments booked for the slots being blocked.</p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="12">
        <Accordion.Header>Can I book the appointments for patients myself?           </Accordion.Header>
        <Accordion.Body>
       <p>Yes, using the “Book Appointment” feature you can book the appointment for your patients. Please refer the doctors’ user manual for details. </p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="13">
        <Accordion.Header>Can my coordinator book appointments on my behalf?            </Accordion.Header>
        <Accordion.Body>
       <p>All efforts are made to make a provision for coordinators to book the appointment on behalf of a doctor as part of upcoming upgrades. </p>
        </Accordion.Body>
      </Accordion.Item>

    </Accordion>
            </Tab.Pane>
            <Tab.Pane eventKey="ConsultCharges" className={key==="ConsultCharges"? "active show":""}>
            <Accordion defaultActiveKey="14" flush className="faq-container">
      <Accordion.Item eventKey="14">
        <Accordion.Header>What is the consultation charge, who decides the charges? What are the charges for first and subsequent visits?</Accordion.Header>
        <Accordion.Body>
        <p>Please reach out to your call centre or front office team to check the charges defined for virtual consult as well as follow-up rules.</p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="15">
        <Accordion.Header>Are there any discounts for PSUs, Corporates?    </Accordion.Header>
        <Accordion.Body>
       <p>As of now, the app works on a Pay-Before-Use basis for all self-paying patients only.</p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="16">
        <Accordion.Header>How will the patient receive the receipt of consultation?   </Accordion.Header>
        <Accordion.Body>
       <p>Patient will receive a receipt over the email immediately after the consult is
              completed by you on MHVC platform.</p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="17">
        <Accordion.Header>How is the payment for booking to be done? Is there any time limit?          </Accordion.Header>
        <Accordion.Body>
        <ul>
            <li>If patient is booking the appointment from patient app, payment can be<br />
            made online via payment gateway using credit card, debit card, net banking,<br />
            UPI or eWallet.</li>
            <li>If the appointment is booked by call centre or by the doctor, a payment link<br />
            is sent to the patient in SMS.</li>
            <li>The payment must be made within 30 minutes of booking the appointment,<br />
            else the slot will be released.</li>
            </ul>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
            </Tab.Pane>
            <Tab.Pane eventKey="DuringVirtualConsult" className={key==="DuringVirtualConsult"? "active show":""}>
            <Accordion defaultActiveKey="18" flush className="faq-container">
      <Accordion.Item eventKey="18">
        <Accordion.Header>If the patient is required to show reports after my advice, would he need to book an appointment? Would he need to pay for it? </Accordion.Header>
        <Accordion.Body>
        <p>The patient will have to book a new appointment to show/discuss their reports. Whether or not you want the follow up to be a paid consult is configurable for you by the front office.</p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="19">
        <Accordion.Header>If during consultation I feel there is not much to be done and I want to cancel, how do I do it? Will the patient get any refund?  </Accordion.Header>
        <Accordion.Body>
       <p>Once the video consult has started, you cannot cancel the appointment. However, you can reach out to call centre and front office team in case you wish to refund the consult fee.</p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="20">
        <Accordion.Header>What if I need to talk to the patient again before prescribing after video consult is over?    </Accordion.Header>
        <Accordion.Body>
       <p>Unless you have hit the “Complete Appointment” button, you can always use the video call icon to call the patient as many times as required. Once the “Complete Appointment” button is clicked, patient cannot be called.</p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="21">
        <Accordion.Header>How would I see the old records of the patients?         </Accordion.Header>
        <Accordion.Body>
       <p>Patients have the provision to upload  documents and/or images for your reference before the consult starts.</p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="22">
        <Accordion.Header>If I need to see more reports when the consultation is on, how would the patient share them?         </Accordion.Header>
        <Accordion.Body>
       <p>Patient can upload more reports from the app before the consult, till the time
            complete appointment button is clicked.</p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="23">
        <Accordion.Header>How will the patient receive the prescription?               </Accordion.Header>
        <Accordion.Body>
       <p>Prescription is sent to the patient via registered email and it can be accessed in their “My Prescription” section of the app as well.</p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="24">
        <Accordion.Header>Is there any time limit within which I must complete the consult?     </Accordion.Header>
        <Accordion.Body>
       <p>Consult must be completed within 2 hours of appointment time. If missed, it will reflect as cancelled.</p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="25">
        <Accordion.Header> Is there any limit to the size of documents to be uploaded by patient?  </Accordion.Header>
        <Accordion.Body>
       <p>The app supports multiple document upload by patients; however, each document should not exceed 1MB in size.</p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="26">
        <Accordion.Header>If it is a follow up patient on MHVC platform, can I see last prescription?            </Accordion.Header>
        <Accordion.Body>
       <p>yes, all past prescriptions done on MHVC can be accessed.</p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="27">
        <Accordion.Header>Can I initiate consultation with next patient before completing the first consultation if I need first patient to upload more reports or provide more information?       </Accordion.Header>
        <Accordion.Body>
       <p>Yes, till the time “Complete appointment is clicked, you can go back and pick
               another patient from “upcoming appointments” for initiating the consult.</p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="28">
        <Accordion.Header> How do I make referrals to other specialty doctor? Can I see their prescription?   </Accordion.Header>
        <Accordion.Body>
        <ul>
            <li>Referral should be advised as per of prescription. Patient has the choice to<br />
            book a virtual consult for referred doctor to avail an online consult.</li>
            <li>Past prescriptions of the patient can be accessed.</li>
            </ul>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="29">
        <Accordion.Header> Prescription will be shared with patients automatically or will be sharing it manually?            </Accordion.Header>
        <Accordion.Body>
       <p>On click to “Complete Appointment” button, prescription will automatically be
         shared with patients via registered email id. It will also be uploaded in the app under “My Prescription” section for the patients.</p>
        </Accordion.Body>
      </Accordion.Item>
   
    </Accordion>
            </Tab.Pane>
            <Tab.Pane eventKey="MHVCPlatform" className={key==="MHVCPlatform"? "active show":""}>
            <Accordion defaultActiveKey="30" flush className="faq-container">
      <Accordion.Item eventKey="30">
        <Accordion.Header>Is the platform secure?         </Accordion.Header>
        <Accordion.Body>
        <p>Yes</p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="31">
        <Accordion.Header>Is there any option to place the scanned signature of doctor on the prescription?       </Accordion.Header>
        <Accordion.Body>
       <p>Yes, MHVC platform support scanned signature. Please reach out to your front
           office or call centre team for help.</p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="32">
        <Accordion.Header>What is the video consultation privacy feature?                    </Accordion.Header>
        <Accordion.Body>
       <p>At no point before, during or after the consult, patient will be able to see the
         doctors’ mobile number. The video call made to the patient is a secure in-app call.</p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="33">
        <Accordion.Header>If more than 20 consultants are using the app, is there any chance that app will hang or get slow down? what is minimum net speed required 2g / 3g/ 4g/ to maintain video quality and speed?  </Accordion.Header>
        <Accordion.Body>
       <p>MHVC platform is tested for performance and speed. 3G and 4G connectivity should be enough to have a smooth video consult. Platform also allows doctors to switch the video off in case any network related challenges are faced.</p>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
            </Tab.Pane>
            
  <Tab.Pane eventKey="TeleMedicineGuidelines" className={key==="TeleMedicineGuidelines"? "active show":""}>
            <Accordion defaultActiveKey="34" flush className="faq-container">
      <Accordion.Item eventKey="34">
        <Accordion.Header>Do I need to take any consent from the patient? </Accordion.Header>
        <Accordion.Body>
        <p>An acceptance of patient’s terms and conditions is taken before payment is made for virtual consult. You will take an explicit consent on mobile app, email, text or audio/video message, only if a virtual consultation is initiated by you. However, If, the patient initiates the telemedicine consultation, then the consent is implied. Please refer Virtual Consult Terms & Conditions for doctors for other details.</p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="35">
        <Accordion.Header>What do I do if there is a need to examine the patient physically?  </Accordion.Header>
        <Accordion.Body>
       <p>If in your clinical / professional judgement, you feel the patient cannot be provided a diagnosis or medication basis the virtual consultation, you shall mention so in the prescription (which you shall maintain as part of each patient’s records) and inform the patient that he or she should visit a hospital or appropriate medical facility. Please note – COMPLETE CONSULT must be done for the patients to get the prescription with advice.</p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="36">
        <Accordion.Header>What happens once the Covid 19 is over? Would the platform stay?  </Accordion.Header>
        <Accordion.Body>
       <p>MHVC platform will continue as there is no guidelines to discontinue virtual consult platform depending on any disease outbreak.</p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="37">
        <Accordion.Header>What are the telemedicine guidelines? What precautions do I need to take?  </Accordion.Header>
        <Accordion.Body>
       <p>Please refer Virtual Consult Terms & Conditions for doctors for details.</p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="38">
        <Accordion.Header>Is there any general consent format with tick mark box which the patient click so that video consultation can start?        </Accordion.Header>
        <Accordion.Body>
       <p>Yes, an acceptance of patient’s terms and conditions is taken before payment is made for virtual consult.</p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="39">
        <Accordion.Header>Is there any disclaimer for the limitation in the video consult?</Accordion.Header>
        <Accordion.Body>
        <p>Yes, prescription typed in MHVC platform carries a standard disclaimer. However, if prescription is uploaded as image or document, the disclaimer is not provisioned.</p>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
            </Tab.Pane>
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>

    <h1 className="text-center mt-5 ">Still have a question?</h1>
    <p  className="text-center fs-5">If you can’t find the answer in our FAQs, <br /> contact us and we’ll get back to you shortly!</p>
               </Container>
               <br />
               <br />
         

            </React.Fragment>
        )
    }


export default FAQ