import React, { useState, useEffect, useLayoutEffect } from "react";

import DownloadApp from "../../component/download-app/downloadApp";
import INTheNews from "./inTheNews";
import AboutusTestimonials from "./testimonials";
import OurTeam from "./ourTeam";
import OurInvestors from "./OurInvestors";
import OurBoard from "./OurBoard";

import { getTextFromCMS } from "../../actions/get-calls";

const About = () => {
  const [content, setContent] = useState(null);

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });

  useEffect(async () => {
    // Content
    const _setContent = await getTextFromCMS(2, 3);
    if (_setContent) setContent(_setContent.data[0]);
  }, []);

  return (
    <React.Fragment>
      <section className="about-banner">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h1>A digital healthcare ecosystem
                providing enhanced patient care
                outcomes by improving
                healthcare delivery services.</h1>
              <p className="mt-2 mt-md-4 mb-0">Backed by a patient centric, data driven philosophy, we offer services such as doctor consultation, telemedicine, diagnostics, health monitoring, homecare services, care programs, preventive health packages and more. Increased patient engagement and better commercial
                value is at the core of our efforts.</p>
            </div>
          </div>
        </div>
      </section>

      <OurBoard />
      <OurInvestors />
      <INTheNews />
      <AboutusTestimonials />
      <OurTeam />
      <DownloadApp />
    </React.Fragment>
  )
}

export default About;