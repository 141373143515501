import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Accordion } from "react-bootstrap";
import { specialitiesListByHospital } from "../../actions/get-calls";

const Specialities = ({
  keyword
}) => {
  const [specialities, setSpecialities] = useState(null);
  const [filteredSpecialities, setFilteredSpecialities] = useState(null);

  useEffect(() => {
    specialitiesListByHospital(15)
      .then(function (response) {
        let res = response.data;
        setSpecialities(res.specialities);
        setFilteredSpecialities(res.specialities);
      })
      .catch(function (err) { });
  }, []);

  useEffect(() => {
    if (keyword) {
      setFilteredSpecialities(specialities.filter((speciality) => speciality.name.toLowerCase().includes(keyword.toLowerCase())));
    } else {
      setFilteredSpecialities(specialities);
    }
  }, [keyword]);

  return (
    <>
      <Accordion defaultActiveKey="" className="specialitiestabs">
        <div className="row">
          {!filteredSpecialities ? (
            <div className="col-lg-12">
              <div className="mhc-Loding text-center my-5">
                <img src="../loader.gif" alt="loader" />
              </div>
            </div>
          ) : <>
            {
              filteredSpecialities.map((item) => {
                return (
                  <div className="col-lg-4" key={item.id}>
                    <Accordion.Item eventKey={item.id} className="mb-4">
                      <Accordion.Header>
                        <Link to={`/booking-consult/consult-doctor`}>
                          <img src={item.icon} alt={item.name} />
                          {item.name}
                        </Link>
                      </Accordion.Header>
                      <Accordion.Body>
                        <ul className="list-unstyled">
                          {item.actions.map((item, index) => {
                            return (
                              <li key={index}><Link to={item.uri}>{item.name}</Link></li>
                            );
                          })}
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                  </div>
                );
              })
            }
          </>
          }
        </div>
      </Accordion>
    </>
  );
};

export default Specialities;
